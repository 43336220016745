@use "../../utilities/colors.scss" as c;

.agreement-wrapper {
  font-family: 'Montserrat';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  .header-text {
    color: c.$accent-grey-color-text;
    text-align: center;
    font-size: 1rem;
    line-height: inherit;
    margin: 2rem 0;
  }

  .semi-m-bot {
    margin-bottom: .5em;
  }

  .trading-service-agreement {
    font-family: monospace;
    overflow: auto;
    font-size: medium;
    max-height: 46vh;
    text-align: left;
    background: c.$deep-blue-color;
    padding: 16px;
    margin-bottom: 18px;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    font-family: 'IBM Plex Mono', monospace;

    div {
      font-family: inherit;

      .simple {
        margin-top: 10px;
        font-family: monospace;
        display: flex;
      }

      .simple-tiers {
        margin-top: 10px;
        font-family: monospace;
        display: block;
      }

      .header {
        margin-top: 20px;
        font-weight: bold;
        /* font-size: large; */
      }

      .highlight {
        margin-top: 20px;
        margin-bottom: 15px;
        font-weight: bold;
        /* font-size: normal; */
      }
    }

    .schedule-amount {
      margin-left: 5%;
    }

    .tier-levels {
      display: table;
      table-layout: fixed;
      width: 75%;
      margin-left: 5%;

      .tier-level-wrapper {
        display: table-row;

        &>.min+.value {
          font-family: monospace;
          display: table-cell;
        }

        &>* {
          font-family: monospace;
          display: table-cell;
        }
      }

      .header>* {
        font-family: monospace;
        font-weight: bold;
        font-size: small;
      }
    }

    .schedule-amount {
      font-weight: bold;
    }
  }

  .agreement-schedules {
    margin-top: 2.3em;

    .font-bold {
      font-weight: 500;
      line-height: 18px;
    }

    .flex-column {

      .font-bold {
        font-size: 0.875rem;
      }

      span {
        margin-bottom: 9px;
      }
    }

    .schedule-a {
      margin-bottom: 23px;

      .flex-between {
        padding-left: 17px;
        margin-bottom: 8px;
      }

    }

    p {
      margin: 25px 15px;
      line-height: 20px;
    }
  }

  .signature-wrapper {
    display: flex;
    justify-content: center;
    gap: 1em;
    margin: 4em 0 3em;
    align-items: flex-end;

    .input-wrapper {
      label {
        font-weight: 500;
      }
    }

    .signature-dated {
      display: flex;
      flex-direction: column;
      width: fit-content;
      max-width: 150px;
      font-size: 75%;
      min-height: 48px;
      justify-content: center;

      > div {
        line-height: 20px;
      }

      .onb-date {
        align-items: center;
        width: 100%;
        min-width: 150px;
        display: flex;
        color: c.$cool-gray-7-color;
      }
    }
  }

  .font-bold {
    font-weight: bold;
    text-align: center;
  }

  .market-subscription {
    font-size: .75em;
    list-style: none;
  }

  .price {
    float: right;
  }

  .flex {
    display: flex;
    justify-content: space-around;
    gap: 1em;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .flex-between {
    display: flex;
    justify-content: space-between;
  }

  .text-left {
    text-align: left;
  }

  .l-h4 {
    line-height: 4;
    font-family: 'Syne';
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 40px;
    display: block;
  }

  .agreement1 {
    align-items: flex-start;
  }

  .agreement2 {
    justify-content: flex-start;
    align-items: flex-start;

    &.main-checkbox {
      margin-bottom: 33px;
    }
  }

  .trading-platform,
  .market-data-subscription,
  .market-data-subscription-sources,
  .market-data-price-summary {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 20px;
    margin: 0 15px 30px;
    align-items: center;

    .font-bold {
      font-size: 1rem;
      font-weight: 600;
      line-height: 22px;
    }
  }

  .monthly-trading-volumes {
    margin-bottom: 30px;
  }

  p.market-data-price-summary-text {
    margin-bottom: 45px;
    color: c.$accent-grey-color-text;
  }

  .market-data-subscription-sources {

    ul {
      margin: 30px 0;
      list-style: none;
      padding: 0;
    }

    li {
      line-height: 22px;
      margin-bottom: 8px;
      font-size: 1rem;
      font-weight: 400;
    }
  }

  .footer-text {
    font-size: 0.875rem;
    line-height: 20px;
    margin: 0 15px;
    color: c.$accent-grey-color-text;
  }
}

@media all and (max-width: 480px) {

  .agreement-wrapper {

    .trading-platform,
    .market-data-subscription,
    .market-data-subscription-sources,
    .market-data-price-summary {
      margin: 0 0 30px;
    }

    .footer-text {
      margin: 0;
    }

    .flex {
      justify-content: space-between;
    }

    .signature-wrapper {
      flex-direction: column;

      .input-wrapper {
        width: 100%;
        align-items: center;
        display: flex;
        flex-direction: column;
      }

      .signature-dated {
        width: 100%;
        max-width: 100%;
        text-align: center;

        .onb-date {
          margin: 0 auto;
          align-items: center;
          width: auto;
          min-width: initial;
        }
      }
    }

    .agreement-schedules {

      p {
        margin: 25px 0;
      }

      .schedule-a {
        .flex-between {
          span {
            &:last-child {
              width: 35%;
              text-align: right;
            }
          }
        }
      }
    }
  }
}