.form-block-signup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .form-wrapper {
    padding: 4rem 6rem;
    .form-group-block {
      .input-wrapper {
        width: 100%;
        color: black;
        input {
          color: black;
          width: 100%;
        }
      }
      .form-control {
        padding: 3px 5px;
        font-size: 0.9rem
      }
    }
    .button-section {
      display: flex;
      align-items: flex-end;
    }
  }

  .password-slogan {
    font-size: xx-small;
  }
}
@media all and (max-width: 550px) {
  .form-block-signup {
    width: 100%;
    .form-wrapper {
      padding: 4rem;
    }
  }
}
.email-input-text-signup {
  background-color: white !important;
}
