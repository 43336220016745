.toast-wrapper {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  z-index: 5;

  .toast-main {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.875rem;
    background-color: rgba(255, 255, 255, 0.95) !important;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 25rem;
    z-index: 6;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .header-button {
        display: inline;
        width: 25px;
        height: 30px;
        border: none;
        color: #90d0ab;
        background-color: transparent;
        &:hover {
          color: #000;
          cursor: pointer;
        }
      }
      button {
        display: none;
      }
      .header-title {
        padding: 10px;
        color: grey;
      }
    }
    .body {
      margin: 20px;
      text-align: center;
    }
    .toast-dialog-buttons {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;

      button {
        height: 25px !important;
        margin: 0 5px;
        align-items: center;
      }
    }
  }

  .greyed-area {
    display: flex;
    align-items: center;
    margin: auto;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);

    z-index: 5;
    justify-content: center;
    text-align: center;
  }
}
.hidden-scrollbar {
  &::-webkit-scrollbar {
    width: 0;
  }
}
.no-scrollbar-padding-right {
  padding-right: 2vw;
}
.no-scrollbar-set-bg {
  background-color: #2c2f36 !important;
}
