@use "src/ui/utilities/colors.scss" as c;

.footer-wrapper {
  margin: 20px 0 0 0;

  .footer-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer-button {
    display: flex;
    justify-content: space-around;
  }

  button {
    padding: 0 28px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    display: block;
    border: 1px solid c.$new-leaf-green-5-color;
    font-family: "Montserrat";
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: 0.96px;
    margin: 0 10px;
    height: auto;
    border-radius: 0 !important;
    background: transparent;
    color: c.$white-color;
    box-shadow: none;

    body[data-button-style^="rounded"] & {
      border-radius: 0 !important;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  .prev-button {
    background-color: transparent;
    color: c.$new-leaf-green-5-color;
    border-color: transparent;
  }

  .submit-app-btn {
    background: linear-gradient(159deg, #419BF6 0%, #44A0F1 13.97%, #4CAEE1 31.62%, #58C4C7 51.17%, #67DEAA 68.61%);
    border: 0;
    color: c.$deep-blue-second-color;
  }
}

@media all and (max-width: 500px) {

  .footer-wrapper {

    .footer-buttons {
      justify-content: center;
    }

    button {
      padding: 0 20px;
      margin: 0 0 0 7px;
      font-size: 0.8rem;

      &:first-child {
        margin: 0 7px 0 0;
      }
    }
  }
}