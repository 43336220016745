.form-block-recovery {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10vh 0;
  margin: 0 auto;
  max-width: 600px;

  .form-wrapper {

    .form-group-block {
      max-width: inherit;
    }
  }

  .navigate-to-login-block {

    span {
      margin-right: 5px;
    }
  }
  .pass-recovery-message-status {
    display: flex;
    max-width: 100%;
    align-self: baseline;
    margin-top: .5em;
    flex-direction: row;
    overflow-wrap: anywhere;
    justify-content: flex-start;

    .pass-recovery-message-email {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    span {
        font-size: .9em;
    }
  }
  .button-signin {
    display: flex;
    align-items: center;
    gap: 6em;
    width: 100%;
  }
}
