@use "./ui/utilities/colors.scss" as c;
@use "./ui/utilities/devices.scss";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;900&family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&family=Syne:wght@400;500;600&display=swap');

.App-onbording {
  @import '~bootstrap/dist/css/bootstrap.min';
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: c.$main-background;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  text-align: center;
  color: c.$white-color;
  min-height: 50vh;

  .mb-3 {
    margin-bottom: 1rem!important;
  }

  .auth-main-layout {
    display: flex;
    flex-direction: column;
    align-items:center;
    padding-top: 83px;
    
    @include devices.desktop {
      padding-top: 103px;
    }
    
    .outlet-block {
      width: 57vw;
      max-width: 1010px;
      line-height: 1.35;
      display: flex;
      flex-direction: column;
      padding: 1.4rem 3vw 2.6rem;
      text-align: initial;
      justify-content: space-between;
      height: 100%;
      background-color: c.$secondary-background;
      margin: 1rem 0;
      min-width: 360px;

      @include devices.desktop {
        min-width: 1010px;
      }
    }
  }
  .checkmark {
    width: 40px;
    height: 15px;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg) translate(0, 50%);
  }
  
  .checkmark_stem {
    position: absolute;
    width: 3px;
    height: 17px;
    background-color: c.$accent-color;
    left: 14px;
    top: -3px;
  }
  
  .checkmark_kick {
    position: absolute;
    width: 9px;
    height: 3px;
    background-color: c.$accent-color;
    left: 7px;
    top: 11px;
  }
  .spinner-wrapper, .submit-spinner-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: c.$main-background;
    z-index: 3;

    .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      color: c.$accent-color;
    }
  }

  .submit-spinner-wrapper {
    position: fixed;
    background-color: rgba(c.$main-background, 0.5);
  }

  .label {
    font-size: .9rem;
  }
  
  h4 {
    font-size: 2rem;
    font-weight: 600;
    font-family: 'Syne';
    color: c.$white-color;
    padding-top: 1.2rem;
    margin-bottom: 1rem;
  }

  h3, h4, h5 {
    text-align: center;
  }
  
  p {
    font-size: 14px;
    line-height: 20px;
  }
  .form-wrapper {
    display: flex;
    padding: 4rem;
    flex-direction: column;
    background-color: c.$secondary-background;
    align-items: flex-start;
    width: 100%;
  
    .form-group-block {
      text-align: left;
      position: relative;
      max-width: 400px;
      width: 100%;
  
      .form-label {
        font-size: .7em;
        color: rgba(10, 10, 10, 0.65);
      }
  
      .see-pasword {
        position: absolute;
        bottom: 5px;
        right: 15px;
        cursor: pointer;
      }
    }
  }
  .error-message {
    padding-bottom: 5px;
    margin-left: 5px;
    color: c.$error-light-red-color;
    border-radius: 5px;
  }
  
  .warn-message {
    padding: 5px;
    margin: 5px;
    color: c.$warn-yellow-color;
    border-radius: 5px;
    a {
      color: white;
    }
  }

  hr:not([size]) {
    height: 1px;
    width: 100%;
  }

  select {
    &:invalid {
      color: c.$deepest-blue-7-color;
    }
    &:disabled {
      opacity: 0.5;
    }
  }

  // .greyed-field {
  //   background-color: #00000012 !important;
  // }
  .white-bg {
    background-color: white !important;
  }
  .apply-opacity {
    opacity: .25;
  }
  .timer {
    color: white;
    background: c.$accent-color;;
    padding: 5px;
    border-radius: 5px;
    height: 29px;
  }

  :not(.input-wrapper) > input[type="text"],
  input[type="date"].form-control {
    background-color: c.$deep-blue-second-color;
    height: 48px;
    word-wrap: normal;
    font-size: 1rem;
    padding: 12px;
    border: 1px solid c.$deep-blue-color;
    color: c.$white-color;

    &:invalid {
      color: gray;
    }
  }

  input[type="date"].form-control {
    position: relative;
    border-radius: 0;

    &::-webkit-calendar-picker-indicator {
      color: transparent;
      background: none;
      z-index: 1;
    }

    &:before {
      color: transparent;
      background-repeat: no-repeat;
      background-position: center;
      z-index: 1;
      content: '';
      width: 25px;
      height: 25px;
      right: 14px;
      position: absolute;
      cursor: inherit;
    }
  }
}

@media all and (max-width: 1010px) {
  .App-onbording {
    .auth-main-layout {
      .outlet-block {
        width: 75vw;
      }
    }
  }
}

@media all and (max-width: 500px) {
  .App-onbording {
    .auth-main-layout {
      .outlet-block{
        width: 100%;
        margin: 0 0 3rem 0;
        padding: 20px 15px;
        min-width: 320px;
      }
    }

    input[type=date].form-control:before {
      width: 17px;
      background-size: 17px;
    }
  }
}

.bold {
  font-weight: 600;
}

::-webkit-scrollbar {
  width: 12px; /* Width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: c.$deepest-blue-2; /* Color of the track */
}

::-webkit-scrollbar-thumb {
  background-color: c.$deepest-blue-4; /* Color of the thumb */
  border-radius: 6px; /* Roundness of the thumb */
  border: 3px solid c.$deepest-blue-2; /* Padding around thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the thumb on hover */
}

/* For Firefox */
* {
  scrollbar-width: thin; /* Width of the scrollbar */
  scrollbar-color: c.$deepest-blue-4 c.$deepest-blue-2; /* Thumb and track color */
}