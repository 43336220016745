@mixin fs-12 {
  font-size: 12px;
  line-height: 16px;
}

@mixin fs-14 {
  font-size: 14px;
  line-height: 20px;
}

@mixin fs-16 {
  font-size: 16px;
  line-height: 22px;
}

@mixin fs-18 {
  font-size: 18px;
  line-height: 22px;
}