@use "../../utilities/colors.scss" as c;

.main-checkbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: .75em;
  flex-wrap: nowrap;

  &>input {
    width: 0;
    height: 0;
    border-radius: 0;
    // pointer-events: none;
    position: relative;
    margin: 10px;
    visibility: hidden;

    &:after {
      visibility: visible;
      width: 20px;
      height: 20px;
      position: absolute;
      top: -10px;
      left: -10px;
      content: '';
      background-repeat: no-repeat;
      background-position: center;
      z-index: 1;
    }

    &:checked {
      &:after {
        background-repeat: no-repeat;
        background-position: center;
        z-index: 1;
      }
    }

    &:disabled {
      &:after {
        opacity: .5;
      }
    }
  }

  label {
    cursor: pointer;
    margin-left: 10px;
    font-family: 'Montserrat';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

.checked-yellow {
  border: 1px dashed c.$deep-blue-color;
  padding: 10px 15px;
  color: c.$yellow-vivid-7-color;
}

.unchecked-transparent {
  border: 1px solid transparent;
  padding: 10px 15px;
}