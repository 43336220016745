@use "../../utilities/colors.scss" as c;

.stepper-block {
  color: grey;
  font-size: xx-large;
}
.stepper-wrapper {
  width: 100%;
  max-width: 1500px;
  display: flex;
  justify-content: space-around;
  padding: 1em 8vw 3em 8vw;
  overflow: hidden;

  .step-wrapper {
    position: relative;
    width: 100%;

    .step-circle-container {
      width: 60px;
      height: 20px;
      z-index: 1;
      background-color: c.$deep-blue-second-color;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .checkmark-icon {
      position: relative;
      width: 60px;
      height: 24px;
      z-index: 1;
      background-color: c.$deep-blue-second-color;

      &:after {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -12px;
        content: '';
        background-repeat: no-repeat;
        background-position: center;
        z-index: 1;
      }
    }

    .step-name {
      font-size: 1rem;
      margin-bottom: 15px;
      font-family: 'Montserrat';
    }
    
    .step-line-wrapper {
      width: 100%;
      height: 20px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .left-short-line,
    .right-short-line {
      position: absolute;
      top: 50%;
      width: 50%;
      height: 4px;
    }

    .left-short-line {
      left: 50%;
    }

    .right-short-line {
      left: 0;
    }
  }
}

.v-stepper-wrapper {
  display: flex;
  flex-direction: column;
  height: 90%;

  .step-wrapper, .first-step-wrapper, .last-step-wrapper {
    position: relative;
    min-height: 130px;
    padding-left: 2em;
    padding-top: .4em;

    .step-name {
      font-size: 1em;
    }
    .step-status {
      color: c.$accent-color;
      font-size: 1.3em;
      line-height: 1;
      padding-left: 1em;
      span {
        color: c.$semi-grey-text-color;
      }
    }
    .step-text,
    .step-text2 {
      padding: 1em 0 0 2em;
      text-align: center;
      color: c.$main-text-color;

    }
    .step-text2 {
      padding: 0 0 0 2em;
    }

    .v-step-line-wrapper {
      position: absolute;
      top: 10px;
      left: 0;
      height: 100%;

      .short-line {
        width: 5px;
        height: 80%;
        position: absolute;
        left: 5px;
        top: 21px;
      }
    }
  }
  .first-step-wrapper {
    height: 90px;
  }
  .last-step-wrapper {
    min-height: 60px;
  }
}

.stepper-from-setup-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 1em 8vw 3em;
}

.not-done-step-background {
  background-color: c.$accent-grey-color;
}

.not-done-step-color {
  color: c.$accent-grey-color-text;
}

.done-step-background {
  background-color: c.$accent-color;
}

.done-step-color {
  color: c.$accent-color;
}

.step-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

@media all and (max-width: 600px) {
  .stepper-wrapper {
    padding: 1em 6vw 3em;
    .step-wrapper {
      .step-name {
        max-width: 80px;
        margin: 0 auto 10px;
      }
    }
  }
}

@media all and (max-width: 480px) {
  .stepper-wrapper {
    padding: 1em 1vw 3em;
  }
}