@use "../../utilities//colors.scss" as c;


.form-block-confirmation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: c.$secondary-background;
    padding: 4em 10em;

    .button-section {
        display: flex;
        align-items: flex-end;
        button {
            margin-top: 1em;
            font-size: 1em;
        }

    }
}
@media all and (max-width: 1010px) {
    .form-block-confirmation{
        padding: 4em;
    }
  }