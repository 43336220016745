@use "src/ui/utilities/colors.scss" as c;

.dropdown-wrapper {
  position: relative;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;

  .label {
    color: c.$white-color;
    margin-bottom: 10px;
    display: block;
    font-size: 0.875rem;
    line-height: 20px;
  }

  select {
    background-size: 24px;
    background-position: calc(100% - 10px) center;
    background-repeat: no-repeat;
    background-color: c.$deep-blue-second-color;
    -webkit-appearance: none;
    height: 48px;
    min-width: 100%;
    width: 100%;
    word-wrap: normal;
    font-size: 1rem;
    padding: 12px 16px;
    border: 1px solid c.$deep-blue-color;
    color: c.$white-color;

    &:invalid {
      color: gray;
    }
  }
}


@media all and (max-width: 1280px) {
  .dropdown-wrapper {
    select {
      background-size: 20px;
      background-position: calc(100% - 5px) center;
    }
  }
}

@media all and (max-width: 480px) {
  .dropdown-wrapper {
    select {
      padding: 12px 10px
    }
  }
}