@use "../utilities/colors.scss" as c;
@use "../utilities/devices.scss";
@import "/src/ui/utilities/mixins.scss";

.taskbar-block {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  background-color: c.$deepest-blue-2;
  border-top: 8px solid c.$vibrant-green-7;
  height: 72px;

  @include devices.desktop {
    height: 100px;
  }

  .taskbar-container {
    display: flex;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;

    .taskbar-brand {
      padding-left: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      @include devices.desktop {
        padding-left: 30px;
      }

      a {
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        padding: 15px 0;

        img {
          height: 40px;

          @include devices.desktop {
            height: 60px;
          }
        }
      }
    }

    .taskbar-collapse-block {
      position: relative;
      display: flex;
      justify-content: flex-end;
      gap: 1em;
      align-items: center;
      padding-right: 30px;
      white-space: nowrap;
      cursor: pointer;

      .taskbar-avatar-letters {
        display: flex;
        position: relative;
        justify-content:center;
        align-items:center;
        height: 32px;
        width: 32px;
        background: linear-gradient(
          159deg,
          #419bf6 0%,
          #44a0f1 13.97%,
          #4caee1 31.62%,
          #58c4c7 51.17%,
          #67deaa 68.61%
        );
        border-radius: 20px;
        color: c.$deepest-blue-2;
        @include fs-14;
        font-weight: 500;

        .dropdown-menu-icon, .dropdown-menu-icon-inverted {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -20px;
        }
  
        .dropdown-menu-icon-inverted {
          transform: translateY(-50%) rotateZ(180deg);
        }
      }

      .taskbar-fullname-block {
        display: flex;
        @include fs-16;
        font-weight: 500;
        pointer-events: none;

        @include devices.mobile {
          display: none;
        }
      }

      .taskbar-logout-button {
        position: absolute;
        bottom: -40px;
        right: 0;
        width: 200px;
        height: 48px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        gap: 12px;
        align-items: center;
        white-space: nowrap;
        background-color: c.$deepest-blue-4;
        border: none;
        color: c.$white-color;
        @include fs-18;
        letter-spacing: 1px;
        font-weight: 500;
        padding: 0;

        @include devices.desktop {
          bottom: -30px;
        }

        img {
          margin-left: 12px;
        }

        &:hover {
          background-color: c.$deepest-blue-5;
        }
      }

      a {
        color: white;
        font-size: 1.5rem;
        text-decoration: none;
        transition: 0.2s ease-in-out;

        &:hover {
          transform: scale(1.1);
          color: c.$accent-color;
          transition: 0.2s ease-in-out;
        }
      }
    }
  }
}
