@use "../colors.scss" as c;


.input-wrapper {
  position: relative;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;

  input {
    border: 1px solid c.$deep-blue-color;
    background: c.$deep-blue-second-color;
    padding: 12px 16px;
    font-size: 1rem;
    line-height: 22px;
    color: c.$white-color;

    &::placeholder {
      color: c.$deepest-blue-7-color;
    }
  }

  .label {
    color: c.$white-color;
    font-size: 0.875rem;
    line-height: 20px;
    color: c.$white-color;
    margin-bottom: 10px;
    display: block;
  }
}

.pep-tooltip {
  top: -12px;
  left: 20px;
  height: 15px;
  width: 15px;
  padding: 1px;
  border: 2px solid #91D1AB;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  cursor: help;

  .i-text {
    color: c.$accent-color;
    font-style: italic;
    font-size: .7em;
    font-weight: 900;
  }
}

.main-button {
  color: #fff;
  background-color: c.$accent-color;
  border-radius: 0!important;
  height: 30px;
  min-width: 100px;
  align-items: center;
  -webkit-box-shadow: 6px 6px 8px -6px #000000;
  box-shadow: 6px 6px 8px -6px #000000;

  border: none;
  cursor: pointer;
  font-size: .8em;
  margin-right: 3px;

  &:disabled {
    color: c.$accent-grey-color;
  }
}