.about-you-container {
  .salut-first-middle {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 30px;

    .salut-first {
      display: flex;
      gap: 2rem;
      width: 57%;

      .input-wrapper {
        width: 75%;

        input {
          width: 100%;
        }
      }

      .dropdown-wrapper {
        width: 25%;

        select {
          width: 100%;
        }
      }
    }

    .input-wrapper {
      width: 40%;

      input {
        width: 100%;
      }
    }
  }
  .last-birth {
    display: flex;
    margin: 2rem 0;
    justify-content: space-between;

    .input-wrapper {
      width: 40%;

      &:first-child {
        width: 57%;

        input {
          width: 100%;
        }
      }
    }
  }
  .city {
    display: flex;
    margin-bottom: 2em;

    .dropdown-wrapper {
      width: 57%;
    }
  }
  .pep-section {
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: flex-start;

    .input-wrapper {
      width: 57%;
      margin: 15px 0;

      input {
        width: 100%;
      }
    }
  }
  .phone {
    display: flex;
    gap: 1em;
    margin: 2rem 0;
    max-width: 57%;

    .input-wrapper {
      width: 100%;

      input {
        width: 100%;
      }
    }
  }
  
  .city-zip {
    display: flex;
    margin: 2rem 0;
    width: 57%;
    gap: 1em;
    justify-content: space-between;

    > div {
      width: 48%;

      input {
        width: 100%;
      }
    }

    .zip-styles {
      .input-wrapper {
        input {
          min-width: 90px;
          width: 100%;
        }
      }
    }
  }
  .street {
    display: flex;
    flex-direction: column;
    gap: 2em;
    max-width: 57%;

    .input-wrapper {
      width: 100%;

      input {
        width: 100%;
      }
    }
  }
  .country {
    display: flex;
    margin: 2rem 0;
    width: 57%;

    .dropdown-wrapper {
      width: 100%;

      select {
        width: 100%;
        min-width: 125px;
      }
    }
  }
}

@media all and (max-width: 1280px) {
  .about-you-container {
    .salut-first-middle {

      .salut-first {
  
        .input-wrapper {
          width: 65%;
        }
  
        .dropdown-wrapper {
          width: 35%;
        }
      }
    }
  }
}

@media all and (max-width: 1010px) {
  .about-you-container {
    .salut-first-middle {
      .salut-first {
        gap: 8px;
      }
    }
  }
}


@media all and (max-width: 480px) {
  .about-you-container {

    .city {
      .dropdown-wrapper {
        width: 100%;
      }
    }

    .pep-section {
      .input-wrapper {
        width: 100%;
      }
    }

    .phone,
    .street {
      max-width: 100%;
    }

    .city-zip,
    .country {
      width: 100%;
    }
  }
}