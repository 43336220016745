@use "./utilities/colors.scss" as c;

.not-layout-block-main {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 10vh 0;
  justify-content: space-around;
  align-items: flex-start;
  height: 100%;

  .left-block {
    color: c.$accent-color;
    font-size: 1em;
    margin: 4em 0;

    h2 {
      color: c.$accent-color;
      margin-bottom: 1.5em;
    }

    ul {
      text-align: left;
      font-size: 1.5em;
      margin: 0 0 1.5em 1.5em;

      li {
        position: relative;

        &::marker {
          color: #2c2f35;
        }
        .checkmark {
          position: absolute;
          left: -30px;
          top: 4px;
          z-index: 2;
        }
        .step-circle {
          position: absolute;
          left: -30px;
          top: 5px;
          z-index: 2;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: c.$accent-grey-color;

          &:first-child {
            background-color: c.$accent-color;
          }
        }
      }
    }
    .lower-testimomial-title {
      text-decoration-line: underline;
    }
  }
  .main-color {
    color: c.$accent-color;
  }
}
.pd-top-10 {
  padding-top: 10vh !important;
}
@media all and (max-width: 1010px) {
  .not-layout-block-main{
    padding: 0;
  }
}

.expired-token-dialog-block {
  display: flex;
  flex-wrap: wrap;
  margin: 10vh 0;
  justify-content: space-around;
  align-items: flex-start;
  height: 100%;

  .expired-token-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: c.$secondary-background;
    padding: 4em 10em;

    .button-section {
      margin-top: 1em;
    }
  }
}
