$accent-color: #67DEAA;
$accent-grey-color: #4C517B;
$accent-grey-color-text: #C6C8D5;

$accent-color-semi-transparent: #90d0aa7a;

$accent-color-dark: #370c0c;

$main-background: #111537;
$secondary-background: #232854;
$disabled-text-background: #e9ecef;

$deepest-blue-1: #060922;
$deepest-blue-2: #111537;
$deepest-blue-3: #232854;
$deepest-blue-4: #383D6A;
$deepest-blue-5: #4c517b;
$deepest-blue-7: #9699B2;
$deepest-blue-8: #C6C8D5;
$deepest-blue-9: #E7E7ED;

$carribean-blue-5: #37BAF3;

$new-leaf-green-5: #67DEAA;

$vibrant-green-7: #78F0C6;

$yellow-vivid-6: #F7C948;

$red-vivid-6: #EF4E4E;
$red-vivid-8: #FF9B9B;

$accent-color-text: white;

$hover-background-color: #555555;
$hover-color: white;

$main-text-color: #797979;
$semi-grey-text-color: #8c8d85;

$warn-yellow-color: #ffa700;

$error-light-red-color: #e1786ed6;
$error-red-color: #E32727;

$white-color: #fff;
$deep-blue-color: #383D6A;
$deep-blue-second-color: #111537;
$new-leaf-green-3-color: #25B376;
$new-leaf-green-5-color: #67DEAA;
$new-leaf-green9-color: #E2FEF2;
$yellow-vivid-7-color: #FADB5F;
$cool-gray-7-color: #9AA5B1;
$deepest-blue-7-color: #9699B2;