@use "../colors.scss" as c;

.rangevalue {
  display: flex;
  flex-direction: row;
  padding: 0px;
  position: relative;
  box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none;
  flex-direction: column;

  input[type="range"] {
    height: 25px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
  }

  input[type="range"]:focus {
    outline: none;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #707070;
    border-radius: 1px;
    border: 0px solid #000000;
  }
  input[type="range"]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #707070;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #91d1ab;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
  }
  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #707070;
  }
  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #707070;
    border-radius: 1px;
    border: 0px solid #000000;
  }
  input[type="range"]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #707070;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #91d1ab;
    cursor: pointer;
  }
  input[type="range"]::-ms-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type="range"]::-ms-fill-lower {
    background: #707070;
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type="range"]::-ms-fill-upper {
    background: #707070;
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type="range"]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #707070;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #91d1ab;
    cursor: pointer;
  }
  input[type="range"]:focus::-ms-fill-lower {
    background: #707070;
  }
  input[type="range"]:focus::-ms-fill-upper {
    background: #707070;
  }
}

.opacite-element {
  opacity: 0.25;
}

.rangevalue .placeholder {
  background-color: initial;
  cursor: initial;
  opacity: 1;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  font-family: 'Montserrat';
}

.rangevalue .placeholder-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 20px 0;
}

.rangevalue .placeholdervalue {
  width: 128px;
}

.rangevalue .placeholder-wrapper .errorlimit {
  color: grey;
  pointer-events: none;
}

.rangevalue .rangeslider {
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
}

.rangevalue .rangeslider .min,
.rangevalue .rangeslider .max {
  margin: 0;
  width: 50px;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  font-family: 'Montserrat';
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 35px;
  text-align: center;
}

.min {
  color: c.$accent-color;
}

.rangevalue {
  max-width: 67%;
  margin: 0 auto 2rem;
}

.rangevalue .rangeslider .errorlimit {
  color: grey;
}

.rangevalue .rangeslider .minmax[valid="false"] {
  color: red;
}

.rangevalue .rangeslider {
  width: 95%;
  margin: 0 auto;

  input {
    width: 100%;
    margin: 0 10px;
    padding: 0px;
    background: none;
    height: 35px;
    border-radius: 20px;
    border: none;
    font-size: small;
  }

  /***** Chrome, Safari, Opera, and Edge Chromium *****/
  input[type="range"]::-webkit-slider-runnable-track {
    background: c.$accent-grey-color;
    height: 4px;
  }

  /******** Firefox ********/
  input[type="range"]::-moz-range-track {
    background: c.$accent-grey-color;
    height: 4px;
  }

  /***** Chrome, Safari, Opera, and Edge Chromium *****/
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -10px; /* Centers thumb on the track */
    background-color: c.$new-leaf-green9-color;
    border-radius: 50px; 
    border: 2px solid c.$new-leaf-green-3-color;
    border-color: c.$new-leaf-green-3-color;
    height: 1.5rem;
    width: 1.5rem;  
  }

  /***** Firefox *****/
  input[type="range"]::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    border-radius: 50px; /*Removes default border-radius that FF applies*/
    border: 2px solid c.$new-leaf-green-3-color;
    border-color: c.$new-leaf-green-3-color;
    background-color: c.$new-leaf-green9-color;
    height: 1.5rem;
    width: 1.5rem; 
  }

}

.rangevalue .rangeslider input[disabled] {
  filter: grayscale(1);
}

.input_form .rangevalue .input-wrapper input {
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 60px;
  margin: 15px;
}

.rangevalue input::placeholder {
  color: transparent;
}

.not-valid-form {
  color: red;
  transform: scale(1.01);
  border-left: 1px dashed red !important;
  margin-bottom: 5px !important;
}
.not-valid-form-message {
  font-size: 12px;
  text-align: initial;
  max-width: fit-content;
  color: c.$error-light-red-color;
}

@media all and (max-width: 1010px) {
  .rangevalue {
    max-width: 100%;
    margin: 1rem auto;

    .rangeslider {
      width: 100%;
    }

    .placeholder-wrapper {
      margin-bottom: 0.625rem;
    }
  }
}