@use "../utilities/colors.scss" as c;
@use "../utilities/devices.scss";
@import "/src/ui/utilities/mixins.scss";

.main-footer-block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: c.$deepest-blue-2;
  text-align: start;

  .main-footer-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 775px;
    padding: 32px 15px;
    color: c.$deepest-blue-7;
    @include fs-16;

    @include devices.desktop {
      padding: 40px 0;
    }

    .main-footer-contacts {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 32px;
      margin-bottom: 24px;

      @include devices.tablet {
        gap: 16px;
      }
      @include devices.mobile {
        gap: 16px;
        flex-wrap: nowrap;
        flex-direction: column;
      }

      .main-footer-contact-item {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        text-decoration: none;
        color: inherit;
      }
    }
  }
}
