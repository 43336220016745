@use "src/ui/utilities/colors.scss" as c;

.business-deal-wrapper {
  .header-text {
    text-align: center;
    color: c.$accent-grey-color-text;
    font-size: 1rem;
    font-family: 'Montserrat';
    margin: 2rem 0;
  }

  .account-type-wrapper {
    width: 35%;
    margin: 0.8rem auto 2rem;

    .dropdown-wrapper {
      margin-top: 2rem;
    }
  }

  .trading-platform-market-data {
    
    nav {
      display: flex;
      justify-content: space-between;
    }

    .platform-selector,
    .marketdata-selector {
      width: 35%;
      margin: 1em auto;
    }

    .traders-fee, .traders-fee-centered {
      margin: 0 auto;
      max-width: 65%;
      font-size: 0.875rem;
      color: c.$accent-grey-color-text;
      font-family: 'Montserrat';
      line-height: 20px;
    }

    .traders-fee-centered {
      max-width: fit-content;
      font-size: 16px;
    }

    .bundle-description {
      min-height: 60px;
      display: flex;
      align-items: center;
    }

    .dropdown-wrapper {
      width: 100%;
    }
  }

  .marketdata-groups {
    max-width: 62%;
    margin: 25px auto 50px;

    .marketdata-group {
      margin-bottom: 30px;

      & > .name {
        padding-left: 3px;
        margin-bottom: 26px;
      }

      .marketdata-group-items {
        .marketdata-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 4px;

          .price {
            .selected {
              font-weight: bold;
            }
          }

          .name {
            .main-checkbox {
              justify-content: left;
              cursor: pointer;
            }
          }
        }
      }
    }

    .marketdata-dependency-items {
      margin-top: 15px;
      font-size: 12px;

      .marketdata-dependency-item {
        font-family: 'Montserrat';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 32px;
      }
    }
  }

  .tier-container-wrapper {
    margin-bottom: 15px;

    & > .name {
      font-size: medium;
      color: rgba(0, 0, 0, 0.65);
    }
    & > .tier-items {
      padding-top: 15px;
      padding-bottom: 15px;

      border-style: solid;
      border-width: 1px;
      border-radius: 3px;
      border-color: rgb(198, 221, 198);
      background-color: white;

      display: table;
      table-layout: fixed;
      width: 100%;
      font-size: small;
    }
    & > .tier-level-wrapper {
        display: table-row;

        .header {
            /* font-weight: bold; */
            font-size: xx-small;
          }
      }
  }


  .tier-level-wrapper:hover:not(:first-child) {
    background-color: #90d0ab;
  }

  .tier-level-wrapper > .min {
    display: table-cell;
  }
  .tier-level-wrapper > .max {
    display: table-cell;
  }
  .tier-level-wrapper > .commission {
    display: table-cell;
  }
  .tier-level-wrapper > .payout {
    display: table-cell;
  }
  .tier-level-wrapper > .overnight {
    display: table-cell;
  }
  .tier-level-wrapper > .software {
    display: table-cell;
  }

  .overnight {
    padding: 40px 0 35px;
  }

  .font-bold {
    font-weight: bold;
    text-align: center;
  }

  .flex {
    display: flex;
    justify-content: space-around;
    gap: 1em;
  }
  .flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
  }

  .schedule-a {
    max-width: 66%;
    margin: 0 auto;
    padding: 15px 0 25px;

    .l-h4 {
      font-family: 'Syne';
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 16px;
      display: block;
    }

    .flex-between {
      font-family: 'Montserrat';
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; 
      padding: 0 20px 8px;
    }
  }

  .market-data-table {
    max-width: 54%;
    justify-content: space-between;
    margin: 35px auto 40px;

    .grey-color {
      color: c.$accent-grey-color-text;
    }
    
    .flex-column:nth-child(1){
      width: 65%;
    }

    .flex-column:nth-child(2){
      width: 35%;
    }

    .flex-column {

      &.monthly-trading-volumes {
        align-items: flex-start;
        white-space: nowrap;
        width: min-content;
      }

      span {
        font-family: 'Montserrat';
        font-size: 1rem;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 8px; 
      }

      > span:nth-child(1) {
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }

  .text-left {
    text-align: left;
  }
  .l-h4 {
    line-height: 4;
  }
}

@media all and (max-width: 1010px) {
  .business-deal-wrapper {
    .account-type-wrapper {
      width: 100%;
      margin: 0.5rem 0;
    }

    .sliders {
      margin: 20px 0 0 0;
    }

    .trading-platform-market-data {
      .platform-selector,
      .marketdata-selector {
        width: 100%;
        margin: 1em auto;
      }

      .traders-fee {
        width: 100%;
        max-width: 100%;
      }
    }

    .market-data-table {
      margin: 25px auto 17px;
      max-width: 100%;
    }

    .marketdata-groups {
      max-width: 90%;
      margin: 25px auto;
    }

    .schedule-a {
      max-width: 100%;
      padding: 0;

      .flex-between {
        padding: 0 0 8px;

        span {
          &:last-child {
            width: 35%;
            text-align: right;
          }
        }
      }
    }
  }
}

@media all and (max-width: 500px) {

  html {
    font-size:14px;
  }

  .rangevalue {
    .placeholder-wrapper {
      flex-direction: column;
    }
  
    .placeholder {
      margin-bottom: 10px;
    }
  }

  .business-deal-wrapper {
    .marketdata-groups {
      .marketdata-group {
        margin-bottom: 15px;

        > .name {
          margin-bottom: 15px;
        }

        .marketdata-group-items {
          .marketdata-item {
            align-items: flex-start;
            margin-bottom: 10px;

            .name {
              width: 74%;

              .main-checkbox {
                align-items: flex-start;
              }
            }

            .price {
              width: 25%;
              text-align: right;
            }
          }
        }
      }
    }
  }
}