@use "../colors.scss" as c;

.upload-wrapper {
  display: flex;
  position: relative;
  border: 0.5px solid c.$accent-color;
  border-radius: 5px;
  padding: 1.2rem;
  flex-direction: row;
  margin: 0 auto;
  justify-content: space-between;
  width: 100%;
  gap: 1em;

  .image-label {
    position: absolute;
    top: -2px;
    font-size: 0.9em;
    left: 16px;
  }
  .upload-spinner-wrapper {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    height: 20%;
    width: 20%;
    background-color: transparent;
    z-index: 3;

    .spinner {
      position: absolute;
      top: 15%;
      right: 20%;
      width: 80%;
      height: 80%;
      color: c.$accent-color;;
    }
  }
  
  .semi-transp-bg {
    opacity: .5;
  }

  .selector {
    width: 100%;
    flex-direction: row;
    display: flex;
    justify-content:center;
    align-items: center;
    position: relative;

    button {
      width: 100%;
      img {
        cursor: pointer;
        width: 20% !important;
        height: 20% !important;
        margin-top: 10%;
      }
    }

    .upload-image-label {
      // text-decoration: underline;
    }

    .upload-image-explanation {
      font-size: 0.6em;
    }
    @media all and (max-width: 1200px) {
      .upload-image-explanation {
        margin: 0;
      }
    }

    .example-image {
      object-fit: contain;
      opacity: 20%;
      width: 100%;
      max-height: 100%;
      height: initial !important;
      box-shadow: 6px 6px 8px -6px #000000;
      transition: ease-in-out 0.2s;
      &:hover {
        opacity: 100%;
      }
    }

    button {
      height: 100%;
      overflow: hidden;
      border-radius: 5px !important;
    }
  }

  .example-remove-buttons {
    position: absolute;
    display: flex;
    gap: 1.5em;
    right: 3%;
    bottom: 0;
    button {
      border: none;
      font-size: 0.6rem;
      background-color: transparent;
      font-weight: 800;
      color: #707070;
      transition: 0.3s ease-out;
      &:hover {
        color: c.$accent-color;
      }
    }
  }
  .bgg-white {
    background-color: white;
  }

  img {
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
    cursor: -moz-zoom-in;
    border-radius: 3px;
    object-fit: contain;
    height: 100% !important;
    width: 100% !important;
  }
  button[hidden] {
    display: none;
  }
}
.fullpreview {
  display: flex;
  align-items: center;
  margin: auto;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);

  z-index: 1081;
  justify-content: center;
  text-align: center;

  cursor: zoom-out;

  .image {
    object-fit: contain;
    width: auto !important;
    height: auto;
    max-width: 70vw !important;
    max-height: 70vh;
    cursor: zoom-out;
    border: none;
    margin: 0;
  }

  .image-info-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 5%;
    padding: 15px;
    max-width: 1000px;
    overflow-x: auto;
    cursor: zoom-out;
    background-color: white;
    border-radius: 10px;

    .image {
      width: 100%;
      max-height: 386px;
      max-width: fit-content;
    }

    .info {
      margin-left: 20px;
    }
  }
}

@media all and (max-width: 1010px) {
  .fullpreview .image-info-block {
    flex-direction: column;
    max-height: 90vh;
    justify-content: end;

    .info {
      margin-left: 0;
      margin-top: 20px;
    }
  }
}
