@use "../../utilities/colors.scss" as c;


.form-block-signin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    position: relative;
  }

  .spinner {
    position: absolute;
    top: 0;
    right: -50px;
    color: c.$accent-color;
  }

  .spaced-between {
    display: flex;
    justify-content: space-between;
  }

  .form-checkbox {
    a {
      margin-left: 10px;
    }
  }
  .button-error-section {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    line-height: 1;
    button {
      width: fit-content;
    }
  }
  .checkbox-sign-in {

    .form-checkbox {
      margin-bottom: .5em;

      .form-check {
        input {
          height: 1em;
          font-size: 1em;
        }
        label {
          vertical-align: text-bottom;
        }
      }
    }
  }
  #password {
      padding-right: 40px;
  }
}
