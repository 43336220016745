@use "./ui//utilities/colors.scss" as c;

body {
  background-color: c.$main-background;
  margin: 0;

  .tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0.25rem;
  }
  .tooltip {
    position: absolute;
    z-index: 1080;
    display: block;
    margin: 0;
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    opacity: 0;

    .tooltip-arrow {
      position: absolute;
      display: block;
      width: 0.8rem;
      height: 0.4rem;
    }
  }
  .show {
    opacity: 0.9;
  }
  .fade {
    transition: opacity 0.15s linear;
  }
}

