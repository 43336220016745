.form-block-activate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 600px;
    min-width: 250px;
    width: 40vw;

    .form-wrapper {

        h4 {
            margin-bottom: 1.5rem;
        }
        .dropdown-wrapper {
            width: 100%;
            max-width: 400px;
            margin-top: 1em;
            select {
                width: calc(100% + 15px);
            }
        }
        input {
            padding-right: 40px;
        }
    }

    .password-slogan {
        font-size: xx-small;
    }

    .repeat-password-eye {
        bottom: 30px !important;
    }
    .bottom-form-side-part {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: .5rem 0 .5rem 1rem;
        padding-right: 1rem;

        .small-caps {
            font-size: x-small;
        }
    }
    .not-match-pass-block {
        max-width: 400px;
        margin-top: 1em;
        color: red;
    }
}
@media all and (max-width: 1010px) {
    .form-block-activate{
        width: auto;
    }
  }