.id-verification-wrapper {

  .placeholder-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
  
    .icon {
      .tooltip-item {
        cursor: pointer;
        right: -12px;
        left: auto;
      }
      svg {
        font-size: 1rem;
        cursor: pointer;
      }
    }
  }
  
  .id-type-number-expire {
    margin-top: 2rem;
    display: flex;
    gap: 20%;
    .input-wrapper {
      width: 100%;

      input {
        width: 100%;
      }
    }
  
    .id-input-field {
      width: 100%;
    }
  }
  @media all and (max-width: 1000px) {
    .id-type-number-expire{
     gap: 2em;
    }
  }
  .expiry-date-section {
    position: relative;
    margin-top: 2em;
    gap: 5px;
    display: flex;

    .checkbox-expiry-section {
      display: flex;
      align-items: center;
      .checkbox{
      
        &>input {
          margin-right: 0;
        }
      }
      span {
        margin-left: -5px;
        font-size: .9rem;
      }
    }
  }
  .front-back-photo {
    display: flex;
    margin-top: 3rem;
    width: 100%;
    gap: 2em;
    flex-wrap: wrap;
  }
}
