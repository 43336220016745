@use "src/ui/utilities/colors.scss" as c;

.account-setup-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  font-size: 16px;
  line-height: 20px;

  .account-setup-title {
    margin-bottom: 0;
  }

  .account-setup-text {
    text-align: center;
    font-weight: 500;

    div {
      &:first-child {
        margin-bottom: 10px;
      }
    }
  }

  .button-element {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 182px;
    height: 56px;
    padding: 16px 12px;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
    background-color: c.$accent-color;
    color: c.$main-background;
    cursor: pointer;

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}
